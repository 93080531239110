@font-face {
  font-family: 'Eina 01';
  font-style: normal;
  font-weight: 500;
  src: local('Eina 01');
  src:
    url('/fonts/Eina01-SemiBold.woff2') format('woff2'),
    url('/fonts/Eina01-SemiBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Eina 01';
  font-style: normal;
  font-weight: 700;
  src: local('Eina 01');
  src:
    url('/fonts/Eina01-Bold.woff2') format('woff2'),
    url('/fonts/Eina01-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Eina 01';
  font-style: normal;
  font-weight: 400;
  src: local('Eina 01');
  src:
    url('/fonts/Eina01-Regular.woff2') format('woff2'),
    url('/fonts/Eina01-Regular.woff') format('woff');
  font-display: swap;
}
