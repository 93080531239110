@import '../../sass/variables';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-center {
      text-align: center;
    }

    .text#{$infix}-left {
      text-align: left;
    }

    .text#{$infix}-right {
      text-align: right;
    }
  }
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

.text-md {
  font-size: 0.9375rem !important;
}

.text-lg {
  font-size: 1.125rem !important;
}

.text-xl {
  font-size: 1.25rem !important;
}

.text-xxl {
  font-size: 1.375rem !important;
}

.text-regular {
  font-weight: $weight-normal !important;
}

.text-medium {
  font-weight: $weight-medium !important;
}

.text-bold {
  font-weight: $weight-bold !important;
}

.text-italic {
  font-style: italic !important;
}

.text-noBreak {
  word-break: keep-all;
}
