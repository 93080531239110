@use 'sass:math';

@import '../../../sass/variables';

.col {
  flex-basis: 100%;
  flex-grow: 1;
  max-width: 100%;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @for $i from 1 through $grid-columns {
      .col-#{$breakpoint}-#{$i} {
        flex-basis: percentage(math.div($i, $grid-columns));
        flex-grow: 0;
        flex-shrink: 0;
        max-width: percentage(math.div($i, $grid-columns));
      }
    }

    .col-#{$breakpoint}-auto {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      max-width: 100%;
    }
  }
}

@each $prefix, $spacing in $grid-spacings {
  .x-#{$prefix} {
    padding-left: $spacing;
  }

  .y-#{$prefix} {
    padding-bottom: $spacing;
  }

  .gap-#{$prefix} {
    padding: 0 0 $spacing $spacing;
  }
}
