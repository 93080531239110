@import '../../../sass/variables';

$nav-height: 75px;

.nav {
  position: sticky;
  top: -1px;
  left: 0;
  z-index: 100;
  width: 100%;
  max-width: 100vw;
  background: linear-gradient(270deg, $colorBlue700 0%, $colorBlue800 100%);
}

.mainWrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $nav-height;
}

.content {
  display: none;
}

.logo {
  justify-content: center;
  width: 105px;
  height: 30px;
}

@include media-breakpoint-up(md) {
  .wrapper {
    justify-content: space-between;
  }

  .content {
    display: block;
  }
}
