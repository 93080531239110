@import '../../sass/variables';

.container {
  border-bottom: 1px solid $colorGray100;

  &:last-of-type {
    border-bottom: none;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0 10px;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  appearance: none;

  &.isOpen {
    .iconWrapper {
      transform: rotate(180deg);
    }
  }

  &:focus {
    outline: none;
  }
}

.iconWrapper {
  pointer-events: none;
  transition: transform $transition;
}

.textContainer {
  pointer-events: none;
}

.icon {
  display: block;
  width: 12px;
  height: 12px;
}

.content {
  overflow: hidden;
  transition: max-height $transition;
}

.title {
  font-size: 1.125rem;
  font-weight: $weight-bold;
  color: $colorGray900;
}

.primary {
  .title,
  .icon {
    color: $colorBlue300;
  }
}

.secondary {
  .title,
  .icon {
    color: $colorWhite;
  }
}

.tertiary {
  .title,
  .icon {
    color: $colorGray900;
  }
}

.subtitle {
  display: block;
  font-size: 0.8125rem;
}

@include media-breakpoint-up(md) {
  .header {
    display: none;
    cursor: initial;

    &.keepHeaderOnDesktop {
      display: flex;
    }

    .icon {
      display: none;
    }
  }

  .title {
    color: $colorGray900;
  }

  .content {
    width: 100%;
    max-height: none !important;
  }
}

@include media-breakpoint-up(lg) {
  .container {
    display: flex;
  }

  .header {
    &.keepHeaderOnDesktop {
      flex-basis: 260px;
      flex-shrink: 0;
    }
  }
}
