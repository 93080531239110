@import '../../sass/variables';

.image {
  max-width: 100%;
  height: auto;
}

// Theme
.rounded {
  overflow: hidden;
  background: $colorWhite;
  border-radius: 6px;
}

.shadow {
  overflow: hidden;
  background: $colorWhite;
  border-radius: 6px;
  box-shadow: 0 10px 45px 0 rgba($colorGray900, 0.05);
}
