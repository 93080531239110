@import '../../../sass/variables';

.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.noWrap {
  flex-wrap: nowrap;
}

@each $prefix, $spacing in $grid-spacings {
  .x-#{$prefix} {
    margin-left: -($spacing);
  }

  .y-#{$prefix} {
    margin-bottom: -($spacing);
  }

  .gap-#{$prefix} {
    margin-bottom: -($spacing);
    margin-left: -($spacing);
  }
}
