@import './sass/variables';
@import './sass/mixins';
@import './sass/fonts';
@import './sass/global';
@import './sass/cssUtilities/index';

p {
  margin: 0;
  line-height: 1.625;
}

// React tooltip
.react-tooltip-lite {
  width: 330px;
  font-size: 0.9375rem;
  font-weight: $weight-normal;
  line-height: 1.4;
  color: $colorWhite;
  border-radius: 2px;
  box-shadow: 0 3px 6px 0 rgba($colorBlack, 0.2);
}

// Slick carousel
.slick-arrow {
  top: 40%;
  right: 0;
}

.slick-prev {
  left: 0;
}

// RC Slider
.rc-slider-rail {
  height: 100%;
  background: $colorGray300;
  border-radius: 4px;
}

.rc-slider-track {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: $colorBlue300;
  border-radius: 4px 0 0 4px;
  opacity: 0.3;
}

.rc-slider-handle {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-top: -12px;
  cursor: pointer;
  background-color: $colorBlue300;
  border-radius: 50%;
}

.rc-slider-mark {
  display: none !important;
}
