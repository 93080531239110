@import '../../../sass/variables';

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint) {
      max-width: $container-max-width;
    }
  }
}
