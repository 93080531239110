@import '../../sass/variables';

.button {
  position: relative;
  display: inline-block;
  font-weight: $weight-medium;
  line-height: 1rem;
  color: $colorGray900;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  transition: background $transition, color $transition, border $transition;
  appearance: none;

  &:active {
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.primary {
    background: $colorYellow200;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background-color: #e6c800;
    }
  }

  &.secondary {
    border-color: $colorGray900;

    &:not(:disabled):focus {
      color: $colorBlue200;
      background-color: rgba($colorBlue200, 0.1);
      border-color: $colorBlue200;
    }

    &:not(:disabled):hover {
      color: $colorBlue200;
      border-color: $colorBlue200;
    }
  }

  &.inverted {
    color: $colorWhite;
    border-color: rgba($colorWhite, 0.3);

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      color: $colorGray900;
      background-color: $colorWhite;
      border-color: $colorWhite;
    }
  }

  &.md {
    padding: 8px 10px;
    font-size: 0.75rem;
  }

  &.lg {
    padding: 10px 15px;
    font-size: 0.875rem;
  }

  &.xl {
    padding: 12px 20px;
    font-size: 1rem;
  }
}

.link {
  display: inline;
  padding: 0;
  font-weight: $weight-medium;
  text-decoration: none;
  vertical-align: baseline;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;

  &.primary {
    color: $colorBlue300;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &.secondary {
    color: $colorBlue300;
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.inverted {
    color: $colorWhite;

    svg {
      opacity: 0.7;
    }
  }

  &.inherit {
    color: inherit;
  }

  &.withIcon {
    display: inline-flex;
  }

  &.xl {
    font-size: 1rem;
  }
}

.block {
  display: block;
  width: 100%;
}

.isProcessing {
  &::after {
    display: inline-block;
    width: 0.5rem;
    text-align: left;
    content: '...';
    transform-origin: left center;
    animation: dotAnimate 1s infinite reverse;
  }

  &:disabled {
    cursor: wait;
  }
}

.external {
  svg {
    overflow: visible;
  }

  path:nth-child(2) {
    transition: transform 100ms $transition-function;
  }
}

.withArrow {
  svg {
    overflow: visible;
  }

  path {
    transition: transform 100ms $transition-function;
  }
}

.external:hover path:nth-child(2),
.external:focus path:nth-child(2) {
  transform: translate3d(2px, -2px, 0);
}

.withArrow:hover path,
.withArrow:focus path {
  transform: translateX(4px);
}

.before.withArrow:hover path,
.before.withArrow:focus path {
  transform: translateX(-4px);
}

@keyframes dotAnimate {
  0% {
    content: '...';
  }

  33% {
    content: '..';
  }

  66% {
    content: '.';
  }
}

@include media-breakpoint-up(sm) {
  .button {
    width: auto;
    max-width: none;

    &.md {
      padding: 9px 20px;
      font-size: 0.875rem;
    }

    &.lg {
      padding: 14px 30px;
      font-size: 1rem;
    }

    &.xl {
      padding: 17px 30px;
      font-size: 1rem;
    }
  }
}
