@import '../../sass/variables';

$nav-height: 65px;

.nav {
  position: sticky;
  top: -1px;
  left: 0;
  z-index: 100;
  width: 100%;
  max-width: 100vw;
}

.mainWrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 100%;
}

.mainContainer {
  max-width: none;
  padding: 0;
}

.wrapper {
  width: 100%;
  background: rgba($colorWhite, 0);
  transition: background 100ms ease-in-out;
}

.head {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  height: $nav-height;
  border: none;
  border-bottom: 1px solid transparent;
}

.headContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 540px;
  padding: 0 20px;
  margin: 0 auto;
}

.logo {
  display: block;
  width: 105px;
  height: 30px;

  svg {
    display: block;
    width: auto;
    height: 100%;
    fill: $colorBlue300;
  }
}

.content {
  position: fixed;
  top: $nav-height;
  bottom: 0;
  width: 100%;
  padding: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms $transition-function, transform 200ms $transition-function;
  transform: translateY(-10px);
  transform-origin: top center;
}

.linksWrapper {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.bottomContainer {
  position: relative;
  flex-shrink: 0;
  max-width: 540px;
  padding: 15px 30px;
  margin: 0 auto;
}

.loginWrapper {
  display: flex;
  flex-shrink: 0;
}

.loginText {
  display: none;
}

.isScrolled {
  .head {
    background: rgba($colorWhite, 0.85);
    backdrop-filter: blur(8px);
  }
}

.isOpen {
  &.nav {
    bottom: 0;
    z-index: $zindex-nav-open-mobile-menu;
  }

  .mainWrapper {
    height: 100%;
    color: $colorGray900;
  }

  .head {
    background: $colorWhite;
    border-bottom: 1px solid $colorGray200;
  }

  .content {
    color: $colorGray900;
    pointer-events: initial;
    opacity: 1;
    transform: translateY(0);
  }

  .scrollable {
    max-height: 100%;
    overflow-y: auto;
    background: $colorWhite;
    -webkit-overflow-scrolling: touch;
  }

  .bottomContainer {
    flex-shrink: 0;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: rgba($colorGray900, 0.8);
    opacity: 0;
    transition: opacity $transition;
    backdrop-filter: blur(10px);

    &.isVisible {
      pointer-events: initial;
      opacity: 1;
    }
  }
}

@include media-breakpoint-up(sm) {
  .loginText {
    display: block;
    margin-right: 10px;
  }

  .headContainer {
    max-width: 720px;
    padding: 0 15px;
  }

  .bottomContainer {
    max-width: 720px;
    padding: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .nav {
    transition: none;
  }

  .head {
    display: flex;
    align-items: center;
    width: auto;
    height: $nav-height;
    margin-right: 20px;
    background: transparent;
    border: none;
    transition: none;
  }

  .content {
    position: static;
    z-index: 1;
    width: 100%;
    padding: 0;
    pointer-events: initial;
    background: transparent;
    opacity: 1;
    transition: none;
    transform: none;
  }

  .mainContainer {
    display: flex;
    align-items: center;
    max-width: 960px;
    height: $nav-height;
    padding: 0 15px;
    margin: 0 auto;
    transition: height $transition;
  }

  .headContainer {
    padding: 0;
  }

  .bottomContainer {
    padding: 0;
    margin: 0;
  }

  .wrapper {
    display: flex;
  }

  .scrollable {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $nav-height;
  }

  .linksWrapper {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .listItem {
    height: 100%;
  }

  .hamburgerContainer,
  .overlay {
    display: none;
  }

  .isScrolled {
    background: rgba($colorWhite, 0.85);
    backdrop-filter: blur(8px);

    .head {
      background: none;
      backdrop-filter: none;
    }
  }

  .isOpen {
    .head,
    .scrollable {
      background: none;
      border: none;
    }

    .scrollable {
      max-height: none;
      overflow: visible;
    }
  }
}

@include media-breakpoint-up(xl) {
  .mainContainer {
    max-width: 1220px;
  }

  .head {
    margin-right: 50px;
  }
}
