@import '../../../sass/variables';

.linkWrapper {
  margin-bottom: 15px;
  margin-left: 30px;

  .link {
    font-weight: $weight-normal;
  }
}

.socialWrapper {
  margin-top: 15px;
}

.link {
  svg {
    color: $colorGray400;
  }
}

.icon {
  display: block;
  width: 16px;
  height: 16px;
  color: $colorGray900;
}

@include media-breakpoint-up(md) {
  .linkWrapper {
    margin-bottom: 0;
  }

  .socialWrapper {
    margin-top: 0;
  }
}

@include media-breakpoint-up(lg) {
  .linkWrapper {
    margin-bottom: 15px;

    &:nth-child(2) {
      padding-bottom: 35px;
      margin-bottom: 0;
      border-bottom: 1px solid $colorGray200;
    }
  }

  .socialWrapper {
    margin-top: 30px;
  }
}
