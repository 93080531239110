@import '../../sass/variables';

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: stretch;
  margin-top: -65px;
}
