@import '../../sass/variables';

.d-hide-mobile {
  display: none;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $displays {
      .d#{$infix}-#{$value} {
        display: $value !important;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .d-hide-mobile {
    display: block;
  }

  .d-hide-desktop {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .d-hide-lg-only {
    display: none;
  }
}

@include media-breakpoint-up(xl) {
  .d-hide-lg-only {
    display: block;
  }
}
