@import '../../../sass/variables';

.wrapper {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transition: opacity $transition;

  &.isVisible {
    opacity: 1;
  }

  .link {
    font-weight: $weight-normal;
  }
}

.link {
  margin-right: 30px;
  font-size: 0.9375rem;
  color: inherit !important;
  transition: color $transition;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 10px;
}

.button {
  width: auto !important;
}

@include media-breakpoint-up(lg) {
  .container {
    justify-content: space-between;
    padding: 0;
    margin: 0;
  }

  .link {
    margin-right: 0;
    font-size: 0.875rem;
  }
}

@include media-breakpoint-up(xl) {
  .link {
    margin-right: 30px;
    font-size: 0.9375rem;
  }
}
