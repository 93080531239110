@import '../../sass/variables';

.wrapper {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    content: '';
  }

  &.overflow-hidden {
    overflow: hidden;
  }
}

.isPulled {
  padding-right: 25px;
  padding-left: 25px;
  margin: 0 -10px;
}

/* Border radius */
.rounded {
  border-radius: 6px;

  &::before {
    border-radius: 6px;
  }
}

/* Theme */
.tiles {
  &::before {
    background: $noisePattern;
    background-color: $colorGray100;
    background-repeat: repeat;
    background-size: 47px 47px;
  }
}

.dark {
  &::before {
    background: linear-gradient(270deg, $colorBlue700 0%, $colorBlue800 100%);
  }
}

.gray {
  &::before {
    background: $colorGray100;
  }
}

.fadeOutBackground {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    pointer-events: none;
    content: '';
    background: linear-gradient(to bottom, rgba($colorWhite, 0) 50%, $colorWhite 100%);
  }
}

@include media-breakpoint-up(md) {
  .isPulled {
    padding-right: 45px;
    padding-left: 45px;
    margin: 0 -45px;
  }

  .rounded {
    border-radius: 12px;

    &::before {
      border-radius: 12px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .isPulled {
    padding-right: 65px;
    padding-left: 65px;
    margin: 0 -45px;
  }
}

@include media-breakpoint-up(xl) {
  .isPulled {
    padding-right: 75px;
    padding-left: 75px;
    margin: 0 -55px;
  }
}
