@use 'sass:math';

@import '../../sass/variables';

.section {
  @each $level, $size in $section-spacing {
    &-pt-#{$level} {
      padding-top: math.div($size, 2);
    }

    &-pb-#{$level} {
      padding-bottom: math.div($size, 2);
    }
  }

  @include media-breakpoint-up(md) {
    @each $level, $size in $section-spacing {
      &-pt-#{$level} {
        padding-top: $size;
      }

      &-pb-#{$level} {
        padding-bottom: $size;
      }
    }
  }
}
