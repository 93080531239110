@import '../../../sass/variables';

.wrapper {
  border-bottom: 1px solid $colorGray200;
}

.mainLink {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 540px;
  padding: 20px 30px;
  margin: 0 auto;
  font-size: 0.9375rem;
  color: inherit;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  background: transparent;
  border: none;
  transition: color 100ms;

  &:hover,
  &:focus {
    color: inherit;
    outline: none;
  }
}

.content {
  max-width: 540px;
  height: 0;
  padding: 0 45px;
  margin: 0 auto;
  overflow: hidden;
  list-style-type: none;
  pointer-events: none;
}

.listItem {
  margin-bottom: 20px;

  &:last-of-type {
    margin: 0;
  }
}

.submenuLink {
  display: block;
  width: 100%;
  font-size: 0.9375rem;
  color: currentColor;
  text-decoration: none;

  &:hover,
  &:focus {
    color: currentColor;

    .text {
      &::after {
        opacity: 1;
        transform: translateY(5px);
      }
    }
  }
}

.arrowWrapper {
  position: absolute;
  top: 50%;
  right: 24px;
  display: block;
  width: 12px;
  height: 12px;
  transition: transform 200ms $transition-function;
  transform: translateY(-50%) rotate(90deg);
  transform-origin: center;
}

.arrow {
  display: block;
  width: 100%;
  height: 100%;
  fill: $colorGray900;
}

.iconWrapper {
  display: none;
}

.description {
  display: none;
}

.isOpen {
  .content {
    height: auto;
    padding: 5px 45px 30px;
  }

  .arrowWrapper {
    transform: translateY(-50%) rotate(270deg);
  }

  .mainLink {
    font-weight: $weight-bold;
  }
}

.isNavOpen {
  .content {
    pointer-events: initial;
  }
}

@include media-breakpoint-up(sm) {
  .mainLink {
    max-width: 720px;
    padding: 20px;
  }

  .content {
    max-width: 720px;
  }
}

@include media-breakpoint-up(lg) {
  .wrapper {
    position: relative;
    height: 100%;
    margin-right: 15px;
    border: none;

    &:last-of-type {
      border: none;
    }
  }

  .content {
    position: absolute;
    top: 100%;
    left: -15px;
    width: 340px;
    height: auto;
    padding: 15px 0;
    overflow: visible;
    color: $colorBlue300;
    pointer-events: none;
    background: $colorWhite;
    border: 1px solid $colorGray200;
    border-radius: 12px;
    box-shadow: 0 6px 20px 0 rgba($colorGray900, 0.1);
    opacity: 0;
    transition: transform 150ms ease-out, opacity 100ms ease-out;
    transform: translate(-50px, -10px);
    transform-origin: top center;
  }

  .isNavOpen {
    .content {
      pointer-events: none;
    }
  }

  .indicator {
    display: block;
    width: 10px;
    height: 10px;
    color: currentColor;
    transition: transform 200ms ease-in-out;
    transform-origin: center;

    svg {
      display: block;
    }
  }

  .mainLink {
    position: relative;
    flex-wrap: wrap;
    height: 100%;
    padding: 10px 5px;
    margin: 0;
    font-size: 0.875rem;
    font-weight: $weight-normal;
    text-align: center;
    transition: color $transition;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 1px;
      content: '';
      background: $colorYellow200;
      box-shadow: 0 0 20px rgba($colorYellow200, 1);
      opacity: 0;
      transition: opacity $transition, transform $transition;
      transform: translateY(-1px);
      transform-origin: center;
    }

    &.isActive {
      &::before {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .listItem {
    margin: 0;
  }

  .submenuLink {
    position: relative;
    display: inline-flex;
    align-items: flex-start;
    width: 100%;
    padding: 15px 30px;
    font-size: 0.9375rem;
    font-weight: $weight-bold;
    text-align: left;
    letter-spacing: 0;
    border-radius: 3px;
    transition: background 300ms ease-in-out;

    &:hover {
      background: rgba($colorBlue300, 0.05);

      .text {
        color: $colorBlue300;
      }
    }

    &:not(&:hover) .iconWrapper {
      path {
        fill: $colorGray900;
      }
    }
  }

  .iconWrapper {
    display: block;
    flex-shrink: 0;
    width: 22px;
    height: 22px;
    margin-right: 20px;
    transform: translateY(4px);

    path {
      transition: fill 200ms ease-in-out;
    }
  }

  .sublinkContent {
    display: block;
    font-size: 0.8125rem;
    font-weight: $weight-normal;
    color: $colorGray500;
  }

  .text {
    position: relative;
    display: block;
    font-size: 1rem;
    font-weight: $weight-medium;
    color: $colorGray900;
    transition: color 300ms ease-in-out;
  }

  .description {
    display: block;
  }

  .submenuGroup {
    padding: 30px 20px;
    border: none;
  }

  .arrow {
    display: none;
  }

  .isOpen {
    .content {
      padding: 15px 0;
      pointer-events: initial;
      opacity: 1;
      transform: translate(-50px, 0);
    }

    .mainLink {
      font-weight: initial;
      background: none;
    }

    .indicator {
      transform: scaleY(-1);
    }
  }

  .isScrolled {
    border-radius: 0 0 4px 4px;

    .content {
      &::before {
        display: none;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .wrapper {
    margin-right: 20px;
  }

  .mainLink {
    padding: 10px;
    font-size: 15px;
  }
}
