@import '../../sass/variables';

.wrapper {
  display: block;
  height: 100%;
}

/* Sizes */
@each $name,
  $size
    in (
      '0': 0,
      '10': 8px,
      '15': 15px,
      '20': 20px,
      '24': 24px,
      '28': 24px,
      '32': 24px,
      '40': 30px,
      '48': 35px,
      '60': 50px,
      '70': 50px,
      '80': 50px,
      '90': 50px,
      '120': 50px,
      '150': 55px,
      '170': 90px
    )
{
  .padding-right-#{$name} {
    padding-right: $size;
  }

  .padding-left-#{$name} {
    padding-left: $size;
  }

  .padding-top-#{$name} {
    padding-top: $size;
  }

  .padding-bottom-#{$name} {
    padding-bottom: $size;
  }
}

@include media-breakpoint-up(sm) {
  @each $name,
    $size
      in (
        '0': 0,
        '10': 8px,
        '15': 12px,
        '20': 18px,
        '24': 20px,
        '28': 24px,
        '32': 28px,
        '40': 36px,
        '48': 44px,
        '60': 52px,
        '70': 64px,
        '80': 72px,
        '90': 80px,
        '120': 90px,
        '150': 100px,
        '170': 120px
      )
  {
    .padding-right-#{$name} {
      padding-right: $size;
    }

    .padding-left-#{$name} {
      padding-left: $size;
    }

    .padding-top-#{$name} {
      padding-top: $size;
    }

    .padding-bottom-#{$name} {
      padding-bottom: $size;
    }
  }
}

@include media-breakpoint-up(lg) {
  @each $name,
    $size
      in (
        '0': 0,
        '10': 10px,
        '15': 15px,
        '20': 20px,
        '24': 24px,
        '28': 28px,
        '32': 32px,
        '40': 40px,
        '48': 48px,
        '60': 60px,
        '70': 70px,
        '80': 80px,
        '90': 90px,
        '120': 120px,
        '150': 150px,
        '170': 170px
      )
  {
    .padding-right-#{$name} {
      padding-right: $size;
    }

    .padding-left-#{$name} {
      padding-left: $size;
    }

    .padding-top-#{$name} {
      padding-top: $size;
    }

    .padding-bottom-#{$name} {
      padding-bottom: $size;
    }
  }
}
