@import '../../sass/variables';

.main {
  padding: 60px 0 10px;
  color: $colorGray900;
}

.title {
  display: block;
  margin-bottom: 30px;
  font-size: 1.125rem;
  color: $colorGray900;
}

.mobileHide {
  display: none;
}

.categoryGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.category {
  padding-left: 30px;
}

.list {
  padding: 10px 0;
  margin-left: 30px;
  list-style-type: none;
}

.wrapper {
  margin-bottom: 20px;
  line-height: 1.3;

  &:last-of-type {
    margin: 0;
  }
}

.group {
  padding: 5px 0;
  border-bottom: 1px solid $colorGray300;
}

.contact {
  order: -1;
  margin-bottom: 40px;
}

.link {
  padding: 5px 10px;
  margin: -5px -10px;
  white-space: nowrap;
}

@include media-breakpoint-up(md) {
  .main {
    padding: 120px 0;
  }

  .mobileHide {
    display: block;
  }

  .category {
    margin-right: 90px;

    &:last-of-type {
      margin: 0;
    }
  }

  .categoryGroup {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .group {
    padding: 0;
    border-bottom: none;
  }

  .list {
    padding: 0;
    margin-left: 0;
  }

  .section > div {
    overflow: visible;
  }
}

@include media-breakpoint-up(lg) {
  .category {
    margin-right: 50px;
  }

  .contact {
    order: initial;
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(xl) {
  .category {
    margin-right: 110px;
  }
}
