@import 'variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: $global-font;
  line-height: 1.15;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba($colorBlack, 0);
}

body {
  width: 100%;
  margin: 0;
  font-size: 0.9375rem;
  font-weight: normal;
  line-height: 1.5;
  color: $body-color;
  text-align: left;
  -webkit-text-shadow: rgba($colorBlack, 0.01) 0 0 1px;
  background-color: $body-bg;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.noWrap {
  word-break: keep-all;
}

a {
  color: $colorBlue300;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

svg {
  display: block;
  overflow: hidden;
  vertical-align: middle;

  &:not(.gradient-icon) {
    fill: currentColor;
  }
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

blockquote {
  margin: 0 0 1rem;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;

  &:not(:disabled) {
    cursor: pointer;
  }
}

table {
  border-collapse: collapse;
}

th {
  text-align: inherit;
}

hr {
  width: 100%;
  margin: 0;
  border: solid $colorGray300;
  border-width: 0 0 1px;
}

@keyframes selectPop {
  to {
    stroke: $colorBlue300;
  }
}

@keyframes strokeAnimation {
  to {
    stroke-dashoffset: 0;
  }
}

// google recaptcha badge
.grecaptcha-badge {
  display: none;
  visibility: hidden;
}
