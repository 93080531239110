@import '../../../sass/variables';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
  font-size: 0.875rem;
  line-height: 1;
  color: $colorGray900;
}

.link {
  font-weight: $weight-normal;
}

.copy {
  margin-top: 30px;
}

@include media-breakpoint-up(lg) {
  .wrapper {
    flex-wrap: nowrap;
    padding-top: 20px;
  }

  .copy {
    position: relative;
    flex: 0 0 auto;
    order: -1;
    padding-right: 15px;
    margin: 0 15px 0 0;
  }
}
