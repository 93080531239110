@import '../variables';

$spacers: (
  0: 0,
  1: 2px,
  2: 4px,
  3: 10px,
  4: 15px,
  5: 20px,
  6: 30px,
  7: 40px,
  8: 50px,
  9: 70px,
  10: 90px,
);

@each $name, $size in $spacers {
  @each $prop, $abbrev in (top: t, bottom: b, left: l) {
    .m#{$abbrev}-#{$name} {
      margin-#{$prop}: $size !important;
    }
  }
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

@include media-breakpoint-up(md) {
  $spacers: (
    0: 0,
    1: 3px,
    2: 6px,
    3: 12px,
    4: 16px,
    5: 25px,
    6: 30px,
    7: 50px,
    8: 70px,
    9: 100px,
    10: 120px,
  );

  @each $name, $size in $spacers {
    @each $prop, $abbrev in (top: t, bottom: b, left: l) {
      .m#{$abbrev}-#{$name} {
        margin-#{$prop}: $size !important;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  $spacers: (
    0: 0,
    1: 4px,
    2: 8px,
    3: 15px,
    4: 20px,
    5: 30px,
    6: 45px,
    7: 60px,
    8: 90px,
    9: 120px,
    10: 150px,
  );

  @each $name, $size in $spacers {
    @each $prop, $abbrev in (top: t, bottom: b, left: l) {
      .m#{$abbrev}-#{$name} {
        margin-#{$prop}: $size !important;
      }
    }
  }
}
