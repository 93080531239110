@import '../../../sass/variables';

.hamburger {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
  overflow: visible;
  font: inherit;
  color: inherit;
  text-transform: none;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  transition: opacity 150ms linear, filter 150ms linear;

  &:hover,
  &:focus {
    outline: none;
  }
}

.hamburgerBox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 20px;
  margin-right: 4px;
}

.hamburgerInner {
  top: 50%;
  display: block;
  margin-top: -1px;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.075s;

  &::before,
  &::after {
    display: block;
    content: '';
  }

  &::before {
    top: -6px;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease;
  }

  &::after {
    bottom: -6px;
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}

.hamburgerInner,
.hamburgerInner::before,
.hamburgerInner::after {
  position: absolute;
  width: 20px;
  height: 1px;
  background-color: currentColor;
  border-radius: 4px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
}

.isActive {
  .hamburgerInner {
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: rotate(45deg);

    &::after {
      bottom: 0;
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: rotate(-90deg);
    }

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
  }
}
