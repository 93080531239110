.fadeInAnimation {
  position: relative;
  visibility: hidden;
  opacity: 0.8;
  transition: opacity 500ms, transform 500ms;
  transform: translateY(5%) scale(0.97);

  &.inView {
    visibility: visible;
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.animation-float-1 {
  animation: float1 5s ease-in-out infinite;
}

.animation-float-2 {
  animation: float2 5.5s ease-in-out infinite;
}

.animation-float-3 {
  animation: float3 6s ease-in-out infinite;
}

@keyframes float1 {
  0%,
  100% {
    transform: translateY(0);
  }

  33% {
    transform: translateY(-5px);
  }

  66% {
    transform: translateY(5px);
  }
}

@keyframes float2 {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-5px);
  }
}

@keyframes float3 {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-5px, -5px);
  }
}
